<i18n lang="yaml">
ja:
  confirm: はい
  cancel: キャンセル
en:
  confirm: OK
  cancel: Cancel
</i18n>

<template>
  <HaBaseDialog v-if="isOpen" class="ho-confirm-dialog" @close="closeDialog">
    <p class="message">{{ message }}</p>
    <slot name="content" />
    <div class="button-wrapper" :class="{ '-hide': hideCancel }">
      <template v-if="!hideCancel">
        <HoMyVketButton
          category="gray"
          outline
          class="button -cancel"
          @click="onCancel"
        >
          {{ closeMessage || i18n.t('cancel') }}
        </HoMyVketButton>
      </template>
      <HoMyVketButton
        category="primary"
        class="button -confirm"
        @click="onConfirm"
      >
        {{ confirmMessage || i18n.t('confirm') }}
      </HoMyVketButton>
    </div>
  </HaBaseDialog>
</template>

<script setup lang="ts">
type Props = {
  isOpen: boolean
  message: string
  closeMessage?: string
  confirmMessage?: string
  hideCancel?: boolean
}

type Emits = {
  (emit: 'confirm'): void
  (emit: 'cancel'): void
  (emit: 'close'): void
}

const i18n = useI18n()

const props = defineProps<Props>()

const emits = defineEmits<Emits>()

const onConfirm = () => {
  emits('confirm')
}
const onCancel = () => {
  emits('cancel')
}
const closeDialog = () => {
  emits('close')
}
</script>
<script lang="ts">
export default defineComponent({
  name: 'HoConfirmDialog',
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use 'sass:color';

.ho-confirm-dialog {
  :deep(> .dialog-window[class]) {
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);

    > .content {
      margin: 0 auto;
      padding: 0;
      width: 100%;
    }

    @include m.sp() {
      // note: %指定に対してfit-contentが効かないので一番上の要素に横幅指定する
      width: 80%;
    }
  }

  .message {
    background: v.$gray;
    border-radius: 5px;
    color: v.$text-body;
    font-size: 16px;
    font-weight: bold;
    padding: v.space(4);
    text-align: center;
    white-space: pre-wrap;
  }
}

.button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  padding: v.space(4) v.space(4) v.space(6) v.space(4);

  &.-hide {
    grid-template-columns: 1fr;
  }

  @include m.sp {
    > .button {
      font-size: 14px;
      height: 40px;
      margin: 0 auto;
      padding: 0 v.space(3);
      width: 90%;
    }
  }
}

.close-button {
  margin: 0 0 v.space(4) auto;
}
</style>
